import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { basePathConfig } from "../../config";
import errorHandler from "../error/errorHandler";
// Thunks
// fetch all Chat History
export const fetchAllChatHistory = createAsyncThunk(
  "chatHistoryViewer/fetch",
  async () => {
    try {
      const bearer_token = `Bearer ${localStorage.getItem("accessToken")}`;
      const accountID = localStorage.getItem("atua");
      if (!!bearer_token && !!accountID) {
        const config = {
          headers: {
            Authorization: bearer_token,
          },
        };
        const res = await axios.get(
          `${basePathConfig.baseURL}/app/chat_history_viewer/accounts/${accountID}`,
          config
        );
        return await res.data;
      } else {
        return { message: "Unauthorized", status: 401 };
      }
    } catch (error) {
      var ErrorData = await errorHandler(error);
      return await ErrorData;
    }
  }
);

// fetch all Chat History
export const fetchAllChatHistoryConversation = createAsyncThunk(
  "chatHistoryViewer/fetchChat",
  async (payloadData) => {
    try {
      const bearer_token = `Bearer ${localStorage.getItem("accessToken")}`;
      const accountID = localStorage.getItem("atua");
      if (!!bearer_token && !!accountID) {
        const config = {
          headers: {
            Authorization: bearer_token,
          },
        };
        const res = await axios.post(
          `${basePathConfig.baseURL}/app/chat_history_viewer/getconversationsdetails`,
          payloadData,
          config
        );
        return await res.data;
      } else {
        return { message: "Unauthorized", status: 401 };
      }
    } catch (error) {
      var ErrorData = await errorHandler(error);
      return await ErrorData;
    }
  }
);

export const STATUSES = Object.freeze({
  SUCCESS: "success",
  ERROR: "error",
  LOADING: "loading",
});

const initialState = {
  data: [],
  fetchData: [],
  editData: [],
  deleteData: [],
  status: STATUSES.SUCCESS,
};

export const chatHistoryViewerSlice = createSlice({
  name: "chatHistoryViewer",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllChatHistory.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(fetchAllChatHistory.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = STATUSES.SUCCESS;
      })
      .addCase(fetchAllChatHistory.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      })
      .addCase(fetchAllChatHistoryConversation.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(fetchAllChatHistoryConversation.fulfilled, (state, action) => {
        state.fetchData = action.payload;
        state.status = STATUSES.SUCCESS;
      })
      .addCase(fetchAllChatHistoryConversation.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      });
  },
});

export default chatHistoryViewerSlice.reducer;
