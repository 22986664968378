import React from "react";
import styled from "@emotion/styled";

import {
  Alert as MuiAlert,
  AlertTitle,
  //Checkbox,
  IconButton,
  InputBase,
  Divider as MuiDivider,
  Grid,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  CircularProgress as MuiCircularProgress,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import PageviewIcon from "@mui/icons-material/Pageview";

import { spacing } from "@mui/system";
import { tableCellClasses } from "@mui/material/TableCell";
import { useDispatch, useSelector } from "react-redux";

import {
  fetchAllChatHistory,
  fetchAllChatHistoryConversation,
} from "../../../../../redux/slices/chatHistoryViewer";
import ChatHistoryViewerModel from "./ChatHistoryViewerModel";
const CircularProgress = styled(MuiCircularProgress)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Paper = styled(MuiPaper)(spacing);
const Alert = styled(MuiAlert)(spacing);

const Search = styled.div`
  margin-top: 5px;
  margin-left: 5px;
  margin-right: 5px;
  background-color: #e7f2ff;
`;

const Spacer = styled.div`
  flex: 1 1 20%;
`;
const CustomTableCell = styled(TableCell)`
  &.${tableCellClasses.head} {
    background: ${(props) => props.theme.palette.tertiary.main};
    color: ${(props) => props.theme.palette.common.black};
    margin-right: 10px;
  }
  &.${tableCellClasses.body} {
    font-size: 14px;
  }
`;

function createData(
  srno,
  id,
  created_at,
  channel_id,
  // agent_name,
  contact_name,
  contact_email
) {
  // Format the date to 'yy-mm-dd'
  const formattedDate = new Date(created_at).toISOString().slice(0, 10);
  return {
    srno,
    id,
    created_at: formattedDate, // Only 'yy-mm-dd'
    channel_id: channel_id.split("_")[0],
    // agent_name,
    contact_name,
    contact_email,
  };
}
var rows = [];
var rowsData = [];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => ({
    el,
    index,
  }));
  stabilizedThis.sort((a, b) => {
    const order = comparator(a.el, b.el);
    if (order !== 0) return order;
    return a.index - b.index;
  });
  return stabilizedThis.map((element) => element.el);
}

const headCells = [
  {
    id: "created_at",
    numeric: false,
    disablePadding: true,
    label: "Date",
  },
  {
    id: "channel_id",
    numeric: false,
    disablePadding: true,
    label: "Channel ",
  },
  // {
  //   id: "agent_name",
  //   numeric: false,
  //   disablePadding: true,
  //   label: "Agent ",
  // },
  {
    id: "contact_name",
    numeric: false,
    disablePadding: true,
    label: "Contact",
  },
  {
    id: "contact_email",
    numeric: false,
    disablePadding: true,
    label: "Email",
  },
  {
    id: "action",
    numeric: false,
    disablePadding: true,
    label: "Actions",
  },
];

const EnhancedTableHead = (props) => {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <CustomTableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding="checkbox"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </CustomTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

function EnhancedTable({
  chatHistoryViewer,
  status,
  dispatch,
  handleAddClickOpen,
}) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(8);
  const [search, setSearch] = React.useState("");

  React.useEffect(() => {
    setDense(true);
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  let emptyRows = rowsPerPage - (rowsPerPage + 1);
  rowsData =
    !!chatHistoryViewer && !!chatHistoryViewer.data
      ? chatHistoryViewer.data.map((item, i) =>
          createData(
            ++i,
            item._id,
            item.created_at,
            item.channel_id,
            //item.agent_name,
            item.contact_name,
            item.contact_email
          )
        )
      : [];
  rows =
    search === ""
      ? rowsData
      : rowsData.filter(
          (i) =>
            i.contact_name.toLowerCase().includes(search.toLowerCase()) ||
            i.channel_id.toLowerCase().includes(search.toLowerCase())
        );

  const searchFilter = (e) => {
    setPage(0);
    setSearch(e.target.value);
  };
  return (
    <div>
      <Paper>
        <Toolbar>
          <div>
            <Typography variant="h6" id="tableTitle">
              Chat History Viewer List
            </Typography>
          </div>
          <Spacer />
        </Toolbar>
        <Search>
          <IconButton type="button" sx={{ p: "4px" }} aria-label="search">
            <SearchIcon />
          </IconButton>
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search"
            //onChange={(e) => setSearch(e.target.value)}
            onChange={(e) => {
              searchFilter(e);
            }}
          />
        </Search>
        <Divider my={2} sx={{ borderBottomWidth: 2 }} />
        <TableContainer>
          {!!status && status === "loading" && (
            <CircularProgress m={2} color="primary" />
          )}
          {!!chatHistoryViewer &&
            !!chatHistoryViewer.status &&
            parseInt(chatHistoryViewer.status) !== 200 && (
              <Alert mb={4} severity="error">
                <AlertTitle>
                  Error: (status-code : {chatHistoryViewer.status})
                </AlertTitle>
                {chatHistoryViewer.message} — <strong>check it out!</strong>
              </Alert>
            )}

          {!!status && status === "error" && (
            <Alert mb={4} severity="error">
              <AlertTitle>Error: </AlertTitle>
              <strong>Something went wrong</strong>
            </Alert>
          )}

          <Table
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {!!rows &&
                stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.date);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, row.date)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.srno}
                        selected={isItemSelected}
                      >
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          //padding="none"
                          padding="checkbox"
                        >
                          {row.created_at}
                        </TableCell>
                        <TableCell align="left">{row.channel_id}</TableCell>
                        {/* <TableCell align="left">{row.agent_name}</TableCell> */}
                        <TableCell align="left">{row.contact_name}</TableCell>
                        <TableCell align="left">{row.contact_email}</TableCell>
                        <TableCell align="left">
                          <PageviewIcon
                            variant="contained"
                            color="primary"
                            style={{ marginRight: "5px", cursor: "pointer" }}
                            onClick={() => {
                              handleAddClickOpen(row);
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 8]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}

function ChatHistoryViewerTable() {
  const dispatch = useDispatch();

  const { data: chatHistoryViewer, status } = useSelector(
    (state) => state.chatHistoryViewer
  );
  React.useEffect(() => {
    dispatch(fetchAllChatHistory());
  }, [dispatch]);

  // show chat history
  const [openAdd, setOpenAdd] = React.useState({
    Open: false,
    data: "",
  });
  const handleAddClickOpen = (item) => {
    const payloadData = {
      conversation_id: item.id,
    };
    dispatch(fetchAllChatHistoryConversation(payloadData));
    setOpenAdd({
      Open: true,
      data: item,
    });
  };

  const handleAddClose = () => {
    setOpenAdd({
      Open: false,
      data: "",
    });
  };

  return (
    <React.Fragment>
      <Grid container spacing={3} style={{ padding: "10px" }}>
        <Grid item xs={12}>
          <EnhancedTable
            chatHistoryViewer={chatHistoryViewer}
            status={status}
            dispatch={dispatch}
            handleAddClickOpen={handleAddClickOpen}
          />
        </Grid>
      </Grid>
      <ChatHistoryViewerModel
        openAdd={openAdd}
        handleAddClose={handleAddClose}
        handleAddClickOpen={handleAddClickOpen}
      />
    </React.Fragment>
  );
}

export default ChatHistoryViewerTable;
